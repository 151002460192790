.pointer {
  cursor: pointer;
}

table {
  font-size: small;
  text-align: center;
}

table td.success {
  color: green;
  background-color: #efe;
}

table td.missing {
  background-color: rgb(253, 255, 238);
}

table td.failure {
  background-color: #fff2ee;
}

.card {
  margin-top: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}

@media (max-width: 992px) {
  table {
    font-size: 0.75em;
  }

  .card {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1em;
  }
}